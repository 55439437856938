import { Fragment, useEffect, useState } from 'react';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import { useDevice } from 'mycs/router/DeviceContext';
import Tooltip from 'mycs/shared/components/Tooltip/Tooltip';
import Button from 'mycs/shared/components/Button/Button';

import styles from './MaterialPopover.scss';

type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  content: React.ReactNode;
  clickOutsideWhiteList?: any[];
  trackLabel?: string;
};

export default function MaterialPopover({
  content,
  clickOutsideWhiteList,
  onClick,
  trackLabel,
  children,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { hasPhoneDimensions } = useDevice();
  let longPressTimer: any;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const onLongPressStart = () => {
    longPressTimer = setTimeout(() => {
      setIsOpen(true);
    }, 1000); // Set the long-press duration
  };

  const onLonPressEnd = () => {
    clearTimeout(longPressTimer);
  };

  function onOpen() {
    setIsOpen(true);
  }

  function onClose() {
    setIsOpen(false);
  }

  if (!isMounted) return <Fragment />;

  return (
    <Tooltip
      className={styles.popover}
      content={content}
      isMobileOverlay
      contentClickClose
      show={isOpen}
      onClose={onClose}
      clickOutsideWhiteList={clickOutsideWhiteList}
    >
      <div
        data-testid="material-popover"
        className={styles.cancelMenu}
        onTouchStart={onLongPressStart}
        onTouchEnd={onLonPressEnd}
        onTouchCancel={onLonPressEnd}
        onClick={onClick}
      >
        <Button trackLabel={trackLabel}>
          <div
            onMouseEnter={hasPhoneDimensions ? noop : onOpen}
            onMouseLeave={hasPhoneDimensions ? noop : onClose}
          >
            {children}
          </div>
        </Button>
      </div>
    </Tooltip>
  );
}
