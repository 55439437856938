import classNames from 'classnames';
import { CfMaterial, MaterialProperty } from '@mycs/contentful';
import Button from 'mycs/shared/components/Button/Button';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import MaterialPopover from 'mycs/samplebox/components/MaterialPopover/MaterialPopover';
import MaterialPopoverContent from 'mycs/samplebox/components/MaterialPopoverContent/MaterialPopoverContent';
import styles from './MaterialPreview.scss';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';
import { useTranslate } from 'mycs/hooks/useTranslate';
import { useDevice } from 'mycs/router/DeviceContext';

type Props = {
  noPreview?: boolean;
  noTitle?: boolean;
  disable?: boolean;
  isAdded?: boolean;
  isOpen?: boolean;
  onToggle?: (material: CfMaterial) => void;
  material: CfMaterial;
  materialProperties?: MaterialProperty[];
  buttonText?: string;
  small?: boolean;
  tiny?: boolean;
  plain?: boolean;
  highlighted?: boolean;
  noHighlight?: boolean;
  dotHighlight?: boolean;
  className?: string;
  imageClassName?: string;
  titleClassName?: string;
  noButton?: boolean;
  disableSampleboxMode?: boolean;
  configuratorLayout?: boolean;
};

export default function MaterialPreview({
  className,
  titleClassName,
  imageClassName,
  isAdded,
  material,
  materialProperties,
  buttonText = 'Add',
  disable = false,
  dotHighlight = false,
  highlighted = false,
  noButton = false,
  noHighlight = false,
  noPreview = false,
  noTitle = false,
  onToggle = () => null,
  plain = false,
  small = false,
  tiny = false,
  disableSampleboxMode = false,
  configuratorLayout = false,
}: Props) {
  const translate = useTranslate();
  const { hasPhoneDimensions } = useDevice();

  const pic = material.thumbnail || material.picture;

  if (!pic) return null;

  const isSampleboxMode = !disableSampleboxMode ? isAdded != null : false;
  const isMaterialAvailable =
    material.available !== undefined ? material.available : true;

  /**
   * On add/remove button click
   */
  const onToggleClick = () => {
    if (isSampleboxMode && !isMaterialAvailable) return;

    onToggle(material);
  };

  const classes = classNames(styles.container, className, {
    [styles.invalid]: disable,
    [styles.added]: isAdded,
    [styles.small]: small,
    [styles.tiny]: tiny,
    [styles.highlighted]: highlighted,
    [styles.dot]: dotHighlight,
    [styles.noHighlight]: noHighlight,
    [styles.configuratorLayout]: configuratorLayout,
  });

  return (
    <div className={classes}>
      {getImage()}

      {getTitle()}

      {isSampleboxMode && isMaterialAvailable && (
        <Button
          className={styles.addButton}
          iconName="general/plus.svg"
          onClick={onToggleClick}
        />
      )}
    </div>
  );

  function getTitle() {
    const title =
      material.name && !noTitle ? (
        <div className={classNames(styles.title, titleClassName)}>
          {material.name}
        </div>
      ) : null;

    return title;
  }

  /**
   * Get the image
   * and wrap it depending on the props
   */
  function getImage() {
    const zigzagBorder = plain ? false : material.type === 'textile';
    const pic = material.thumbnail || material.picture;

    let image = (
      <SmartImage
        src={
          material.thumbnail
            ? pic.url
            : ContentfulService.getThumbUrl(pic.url, 110)
        }
        title={material.name}
        alt={material.name}
        aspectRatio="1/1"
      />
    );

    if (!noPreview && material.picture) {
      const popoverContent = (
        <MaterialPopoverContent
          add={onToggleClick}
          description={material.description}
          name={material.name}
          src={
            material.picture && material.picture.url ? material.picture.url : ''
          }
          buttonText={buttonText}
          small={tiny}
          hideButton={noButton}
          materialProperties={materialProperties}
          titleDetails={translate('material-details')}
          titleProperties={translate('material-characteristics')}
        />
      );

      image = (
        <MaterialPopover
          content={popoverContent}
          onClick={onToggleClick}
          trackLabel={material.color}
        >
          {image}
        </MaterialPopover>
      );
    }

    image = (
      <div className={classNames(styles.image, imageClassName)}>
        <div
          className={classNames({
            [styles.invalidImage]: disable,
          })}
        >
          <div
            className={classNames({
              [styles.zigzagWrapper]:
                !hasPhoneDimensions && !configuratorLayout && zigzagBorder,
            })}
          >
            <div
              className={classNames({
                [styles.zigzag]:
                  !hasPhoneDimensions && !configuratorLayout && zigzagBorder,
              })}
            >
              {image}
            </div>
          </div>
        </div>
      </div>
    );

    // Click is handled in the MaterialPopover in case there's a preview
    if (noPreview) {
      image = (
        <Button
          onClick={onToggleClick}
          trackLabel={material.color}
          className={styles.button}
        >
          {image}
        </Button>
      );
    }

    return image;
  }
}
